.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(238, 234, 230);
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
  }
  @media screen and (max-width: 600px) {
    .cookie-popup {
      flex-direction: column;
      align-items: center;
    }
    .cookie-popup button {
        margin-top: 10px;
    }
  }
  
  .cookie-popup.active {
    transform: translateY(0);
  }
  
  .cookie-popup button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    margin: 5px;
  }
  
  .cookie-popup .accept {
    background: rgb(34,195,70);
background: -moz-linear-gradient(0deg, rgba(34,195,70,1) 0%, rgba(45,253,180,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(34,195,70,1) 0%, rgba(45,253,180,1) 100%);
background: linear-gradient(0deg, rgba(34,195,70,1) 0%, rgba(45,253,180,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22c346",endColorstr="#2dfdb4",GradientType=1);
  }
  
  .cookie-popup .accept:hover {
    background-color: #3e9f3e;
  }
  
  .cookie-popup .reject {
    background: rgb(252,176,69);
background: -moz-linear-gradient(180deg, rgba(252,176,69,1) 0%, rgba(253,29,29,1) 100%);
background: -webkit-linear-gradient(180deg, rgba(252,176,69,1) 0%, rgba(253,29,29,1) 100%);
background: linear-gradient(180deg, rgba(252,176,69,1) 0%, rgba(253,29,29,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcb045",endColorstr="#fd1d1d",GradientType=1);}
  
  .cookie-popup .reject:hover {
    background-color: #d32f2f;
  }