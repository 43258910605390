.cool-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.cool-button:hover {
  transform: scale(1.1) rotate(5deg);
}


.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 95%;
  }
}

.left-container-w-text {
  padding: 0.5rem;
  width: 40%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 992px) {
  .left-container-w-text {
    width: 95%;
  }
}


.right-container-w-img {
  width: 40%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: auto;
  overflow: hidden;
}
@media (max-width: 992px) {
  .right-container-w-img {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.right-container-w-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
@media (max-width: 992px) {
  .right-container-w-img img{
    width: 100%;
    align-items: center;
    justify-content: center;

  }
}
h1 {
  font-size: clamp(25px, 10vw, 50px);
}

/* TODO THIS DOESN't Work */
/* @media (max-width: 992px) {
  .section h1 {
    font-size: 50px;
  }
} */


.single-article{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
}

/*  */
.right-container-w-text {
padding: 0.5rem;
width: 40%;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
padding-bottom: 1rem;
padding-top: 1rem;
}
@media (max-width: 992px) {
.right-container-w-text{
width: 95%;
}
}


.left-container-w-img {
width: 40%;
height: auto;
overflow: hidden;
}
@media (max-width: 992px) {
.left-container-w-img {
  width: 100%;
  align-items: center;
  justify-content: center;
}
}

.left-container-w-img img {
  width: 100%;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  object-fit: cover;
}
@media (max-width: 992px) {
.left-container-w-img img{
  width: 100%;
  align-items: center;
  justify-content: center;

}
}


/*  */

.section2 {
  height: 85vh;
  background-position: center;
  background-size: cover;
  color: #fff;
  text-align: center;
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2rem;
}

h2 {
  margin-bottom: 1rem;
  font-size: clamp(2rem, 10vw, 3rem);
  font-weight: 700;
  font-family: coolvetica, helvetica, roboto, courier;
  color: black
}

p {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: coolvetica, helvetica, roboto, courier;
  color: black;
}

.cool-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.cool-button:hover {
  transform: scale(1.1) rotate(5deg);
}

.blog-section {
  display: flex;
  justify-content: space-evenly

}

.container3 {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-items: flex-start;
flex-direction: row;
width: 90%;
}
.article {
width: calc(33.333% - 2rem);
margin-bottom: 4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
@media (max-width: 992px) {
  .container3 {
      justify-content: center;
      width: 100%;
      align-items: center;
      flex-direction: column;
  }
  .article {
      width: 90%;
      margin-bottom: 2rem;
  }
  }



.article img {
width: 100%;
height: auto;
object-fit: cover;
margin-bottom: 1rem;
}

.article h3 {
margin-bottom: 0.5rem;
font-size: 1.5rem;
font-weight: 300}

.form {
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.form h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.form label {
  display: block;
  margin: 1rem;
  font-size: 1rem;
  font-weight: 500;
}

.form select,
.form textarea,
.form input {
  width: 100%;
  max-width: 600px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form select {
  height: 3.5rem;
  appearance: none;
  background-color: #fff;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
}

.form textarea {
  height: 10rem;
  resize: vertical;
}

.form input[type="email"] {
  height: 2.5rem;
}

.form button[type="submit"] {
  display: block;
  margin: 2rem auto;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form button[type="submit"]:hover {
  background-color: #444;
}


/*  belongs to FlexGrid
.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.flex-item {
  flex: 50% 0 50%;
  margin: 1rem;
  text-align: center;
}

.flex-item h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.flex-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1rem;
}

.flex-item button {
  display: block;
  width: 100%;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.flex-item button:hover {
  background-color: #444;
}

@media (max-width: 992px) {
  .flex-item {
    flex: 0 0 100%;
  }
} */

/* SECOND FLEXGRID
.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.flex-item {
  flex: 0 0 33.3333%;
  margin: 1rem;
}

.flex-item h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.flex-item p {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

.flex-item .portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}

.flex-item .portfolio-item {
  flex: 0 0 50%;
  margin: 0.5rem;
  text-align: center;
}

.flex-item .portfolio-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 1rem;
}

.flex-item .portfolio-item h3 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
} */


.flex-grid {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-auto-rows: 1fr;
}

figure {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 768px) {
  .flex-grid {
    grid-template-columns: 1fr;
    margin: 0;
    width: 100%
  }
  .flex-grid p {
    margin: auto;
    padding: 0.5rem;
    font-size: medium;
  }
  figure {
    flex-direction: row;
  }
  /* Makes every even number figure container reversed */
  figure:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  /* Max width 50% and height: auto for responsive image that takes up 50% of width */
  .flex-grid-pics {
    max-width: 50%;
    height: auto;
    object-fit: cover;
  }
  .text-container {
    max-width: 50%;
  }
}

.footer {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  text-align: center;
}

.footer span {
  padding: 1rem;
  max-width: 100px;
  word-wrap: break-word;
}

.social-links {
  height: 20vh;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .social-links {
   height: 25vh;
  }
}

/* create the clip-path using an SVG element */
#mask {
  width: 100%;
  height: 100%;
}

.AnimatingText{
    background:url("https://media4.giphy.com/media/7LsYKNn60KhUs/giphy.gif?cid=ecf05e47buz1wr3kg06d6sj0kbfmy4fv42883yn9m47ke2rl&rid=giphy.gif&ct=g") repeat;
 background-position:10px;
  -webkit-background-clip: text;
  background-clip: text;
   color: transparent;
   font-size: clamp(3rem, 15vw, 20rem);
   font-family:coolvetica, helvetica, roboto, courier;
 font-weight:bold;
 margin:0 auto;
 /* background-position-x: 52%; */
 /* background-position-y: 48px; */
}

.AnimatingTextMedium{
  background: url("https://media4.giphy.com/media/7LsYKNn60KhUs/giphy.gif?cid=ecf05e47buz1wr3kg06d6sj0kbfmy4fv42883yn9m47ke2rl&rid=giphy.gif&ct=g") repeat;
 background-position: 10px;
  -webkit-background-clip: text;
  background-clip: text;
   color: transparent;
   font-size: clamp(1.5rem, 10vw, 10rem);
   font-family: coolvetica, helvetica, roboto, courier;
 font-weight: bold;
 margin: 0 auto;
 /* background-position-x: 52%; */
 /* background-position-y: 48px; */
}

.AnimatingTextSmall{
  background:url("https://media4.giphy.com/media/7LsYKNn60KhUs/giphy.gif?cid=ecf05e47buz1wr3kg06d6sj0kbfmy4fv42883yn9m47ke2rl&rid=giphy.gif&ct=g") repeat;
 background-position:10px;
  -webkit-background-clip: text;
  background-clip: text;
   color: transparent;
   font-size: clamp(1rem, 5vw, 5rem);
   font-family:coolvetica, helvetica, roboto, courier;
 font-weight:bold;
 margin:0 auto;
 /* background-position-x: 52%; */
 /* background-position-y: 48px; */
}


.blurred-bg-img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: 85vh;
  background-position: center;
  background-size: cover;
  color: #fff;
  filter: blur(2px);
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.text-over-bg {
  position: absolute;
  top:0;
  z-index: 2;
}

.typewriter {
  width: 100%;
  animation: typing 2s steps(48), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: clamp(small, 15vw, 5rem);
  }
  
  @keyframes typing {
  from {
    width: 0
  }
  }
    
  @keyframes blink {
  50% {
    border-color: transparent
  }
  }
  
.SubtitleThing {
  font-size: clamp(0.60rem, 2vw, 3rem);
  width: 90%;
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: coolvetica, helvetica, roboto, courier;
  color: black;
}
.SubtitleThingMedium {
  font-size: clamp(.60rem, 3vw, 3rem);
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: coolvetica, helvetica, roboto, courier;
  color: black;
}
.SubtitleThingSmall {
  font-size: clamp(.60rem, 2vw, 2rem);
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: coolvetica, helvetica, roboto, courier;
  color: black;
}


.moveBox {
 margin:0 auto;
 width:100%;
}
 /* .gifheader { */
  /* font-size: 100px; */
  /* font-weight: bold; */
  /* color: red; */
  /* text-align: center; */
  /* mask: url(#mask); apply the mask to the header */
  /* -webkit-mask: url(#mask); apply the mask to the header for webkit browsers */
  /* background-image: url("https://giphy.com/gifs/nfl-sports-football-sport-YjzvA15X6IJRUBOBR1"); set the GIF as the background image */
  /* background-repeat: repeat; don't repeat the GIF */
  /* background-size: cover; scale the GIF to fit the mask */
/* } */

/* create the mask using an SVG element */
/* #mask { */
/* width: 100%; */
/* height: 100%; */
/* } */
a, a:hover, a:focus, a:active {
text-decoration: none;
color: inherit;
}

