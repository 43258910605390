.navbar-hidden {
    transition: ease-out 0.2s;
    z-index: -1;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(32, 146, 89);
    color: #fff;
    height: 64px;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  
  .navbar-brand {
    padding-left: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
  }
  
  .navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .navbar-toggler-icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    background-size: cover;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox%3D%220 0 30 30%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath stroke%3D%22%23fff%22 stroke-width%3D%223%22 stroke-linecap%3D%22round%22 stroke-miterlimit%3D%2210%22 d%3D%22M4 7h22M4 15h22M4 23h22%22%2F%3E%3C%2Fsvg%3E');
  }

  .navbar-toggler-close {
    display: inline-block;
    padding-top: 0.5rem;
    background-size: cover;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox%3D%220 0 30 30%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath stroke%3D%22%23fff%22 stroke-width%3D%223%22 stroke-linecap%3D%22round%22 stroke-miterlimit%3D%2210%22 d%3D%22M4 7h22M4 15h22M4 23h22%22%2F%3E%3C%2Fsvg%3E');
    background: inherit;
    border: none;
    justify-content: center;
    cursor: pointer;
  }
  
  .navbar-collapse {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    background-color:  #AD8;
    overflow-y: auto;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
  
  .navbar-collapse.show {
    max-height: 400px;
  }
  
  .navbar-nav {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    margin: 0 1rem;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
    transition: background-color 0.2s ease-out;
  }
  
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column;
    }
  
    .navbar-toggler {
      display: block;
    }
  
    .nav-item {
      margin: 0.5rem 0;
    }
  }
  